import axios from "axios";

const setAuthAdminToken = (token) => {
  console.log("setAuthAdminToken is called");
  if (token) {
    axios.defaults.headers.common = {
      "x-access-token": token,
    };
  } else {
    axios.defaults.headers.common = {
      "x-access-token": token,
    };
  }
};
export default setAuthAdminToken;
