import { GET_LOGGIN_ARTIST_BY_ID } from "../actions/types";

const initialState = {
    profileArtistInfo: [],
};

function profileArtistInfo(state = initialState, action) {
  switch (action.type) {
    case GET_LOGGIN_ARTIST_BY_ID:
      return {
        ...state,
        profileArtistInfo: action.payload,
      };
    default:
      return state;
  }
}

export default profileArtistInfo;