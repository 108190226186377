import { REQUEST_UPLOAD_FILES_TO_THE_SERVER } from "../actions/types";

const initialState = {
  requestUploadFilesStatus: [],
};

function requestUploadFilesStatus(state = initialState, action) {
  switch (action.type) {
    case REQUEST_UPLOAD_FILES_TO_THE_SERVER:
      return {
        ...state,
        requestUploadFilesStatus: action.payload,
      };
    default:
      return state;
  }
}

export default requestUploadFilesStatus;
