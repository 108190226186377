import { SET_UPLOAD_PHOTO, SET_UPLOAD_AVATAR, SET_UPLOAD_PROFILE_IMG, SET_UPLOAD_PROFILE_MOBILE_IMG } from "../actions/types";

const initialState = {
  selectedPhoto: [],
  selectedAvatar: [],
  selectedProfileImg: [],
  selectedProfileMobileImg: []
};
// RR: This function takes in action because we are going to dispatch actions to reducer
function selectedPhoto(state = initialState, action) {
  switch (action.type) {
    case SET_UPLOAD_PHOTO:
      return {
        ...state,
        selectedPhoto: action.payload,
      };
    case SET_UPLOAD_AVATAR:
      return {
        ...state,
        selectedAvatar: action.payload,
      };
    case SET_UPLOAD_PROFILE_IMG:
      return {
        ...state,
        selectedProfileImg: action.payload,
      };
    case SET_UPLOAD_PROFILE_MOBILE_IMG:
      return {
        ...state,
        selectedProfileMobileImg: action.payload,
      };
    default:
      return state;
  }
}

export default selectedPhoto;
