import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const ProtectedRoute = ({ component: Component, forgotPassword, ...rest }) => {


  console.log("this is ProtectedRoute:",JSON.stringify(forgotPassword));

  return (
    <Route
      {...rest}
      render={(props) =>
        forgotPassword.isAuthenticated === true  && rest.computedMatch.params.token === localStorage.getItem('key') ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  forgotPassword: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    forgotPassword: state.forgotPassword,
});

export default connect(mapStateToProps)(ProtectedRoute);
