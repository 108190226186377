import { SET_PERCENTAGE_INFO_STATUS } from "../actions/types";

const initialState = {
    percentageInfoStatus: true,
};

function percentageInfoStatus(state = initialState, action) {
  switch (action.type) {
    case SET_PERCENTAGE_INFO_STATUS:
      return {
        ...state,
        percentageInfoStatus: action.payload,
      };
    default:
      return state;
  }
}

export default percentageInfoStatus;