import { GET_ERRORS, SET_CURRENT_ADMIN } from "../actions/types";
import isEmpty from "../validation/isEmpty";

const initialState = {
  isAuthenticated: false,
  admin: {},
};

function authAdmin(state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        admin: action.payload,
      };
    case SET_CURRENT_ADMIN:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        admin: action.payload,
      };
    default:
      return state;
  }
}

export default authAdmin;
