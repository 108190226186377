import {
  GET_ERRORS,
  AUTH_FORGOT_PASSWORD_ARTIST,
  EXPIRED_FORGOT_PASSWORD_TOKEN,
  FORGOT_PASSWORD_ARTIST,
  CHANGE_FORGOT_PASSWORD_ARTIST
} from "./types";

import PasswordService from "../services/passwordService";
import setPasswordToken from "../utils/setPasswordToken";
import jwt_decode from "jwt-decode";

// Get forgot password Token for artist
export const authForgotPasswordArtist = (email) => (dispatch) => {
  return PasswordService.forgotPasswordArtist(email)
    .then((res) => {
      // Save to localStorage
      const token = res.artist.token;

      console.log("inside passwordActions: " + JSON.stringify(res.artist.key));
      // Set token to ls
      localStorage.setItem("jwtPasswordToken", token);
      localStorage.setItem("key", res.artist.key);

      // Set token to PasswordToken header
      setPasswordToken(token);

      // Decode token to get artist data
      const decoded = jwt_decode(token);
      console.log("decoded token", JSON.stringify(decoded));

      //empty old authentication forgot Password Artist
      
      dispatch(setAuthForgotPasswordArtist(decoded));

      dispatch({
        type: FORGOT_PASSWORD_ARTIST,
        payload: res.success,
      });

      return res.success;

    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: { errors: err.response },
      });
      return Promise.reject(err);

    });
};

// Set Authentification artist for forgot Password
export const setAuthForgotPasswordArtist = (decoded) => {
  return {
    type: AUTH_FORGOT_PASSWORD_ARTIST,
    payload: decoded,
  };
};

export const expiredForgotPasswordArtist = () => (dispatch) => {
  // Remove password token from localStorage
  try {
    console.log("---------------------------->In  expiredForgotPasswordArtist");
    localStorage.removeItem("jwtPasswordToken");
    localStorage.removeItem("key");
    setPasswordToken(false);

    dispatch(setAuthForgotPasswordArtist({}));

    dispatch({
      type: EXPIRED_FORGOT_PASSWORD_TOKEN,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};


export const changeForgotPasswordArtist =
  (anArtistId, anEmail, anNewPassword) => async (dispatch) => {
    try {
      const res = await PasswordService.changeForgotPasswordArtist(
        anArtistId,
        anEmail,
        anNewPassword
      );

      dispatch({
        type: CHANGE_FORGOT_PASSWORD_ARTIST,
        payload: res,
      });

      return res;
    } catch (err) {
      return Promise.reject(err);
    }
  };

