import reportWebVitals from "./reportWebVitals";
import React from "react";
import ReactDOM from "react-dom";
import store from "./store";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import setAuthToken from "./utils/setAuthToken";
import setAuthAdminToken from "./utils/setAuthToken";
import setPasswordToken from "./utils/setPasswordToken";

import jwt_decode from "jwt-decode";
import { setCurrentArtist, logoutArtist } from "./actions/authActions";
import { setCurrentAdmin, logoutAdmin } from "./actions/authAdminActions";
import {
  setAuthForgotPasswordArtist,
  expiredForgotPasswordArtist,
} from "./actions/passwordActions";

import PrivateRoute from "./common/PrivateRoute";
import PrivateRouteAdmin from "./common/PrivateRouteAdmin";
import ProtectedRoute from "./common/ProtectedRoute";
import "./i18n";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Pages
const Login = React.lazy(() => import("./components/Login/login"));
const DeleteAccount = React.lazy(() => import("./components/DeleteAccount/DeleteAccount.js"));
const Register = React.lazy(() =>
  import("./components/register/view/Register")
);
const ForgotPassword = React.lazy(() =>
  import("./components/ForgotPassword/component")
);
const App = React.lazy(() => import("./App.js"));
const AppAdmin = React.lazy(() => import("./AppAdmin.js"));

if (localStorage.jwtToken) {
  // Set auth token header auth

  setAuthToken(localStorage.jwtToken);

  var decoded = jwt_decode(localStorage.jwtToken);
  // Set User and is Authenticated
  store.dispatch(setCurrentArtist(decoded));

  // Now if you reload a page, if the user has already logged-in you will still have his info in he state

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout User
    store.dispatch(logoutArtist());
    // Redirect to login
    window.location.href = "/";
  }
}

if (localStorage.jwtPasswordToken) {
  setPasswordToken(localStorage.jwtPasswordToken);

  var decoded = jwt_decode(localStorage.jwtPasswordToken);

  store.dispatch(setAuthForgotPasswordArtist(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(expiredForgotPasswordArtist());

    window.location.href = "/";
  }
}

if (localStorage.adminJwtToken) {
  // Set auth token header auth

  setAuthAdminToken(localStorage.adminJwtToken);

  // Decode token and get user info and expiration
  var decoded = jwt_decode(localStorage.adminJwtToken);
  // Set User and is Authenticated
  store.dispatch(setCurrentAdmin(decoded));

  // Now if you reload a page, if the user has already logged-in you will still have his info in he state

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout User
    store.dispatch(logoutAdmin());
    // Redirect to login
    window.location.href = "/";
  }
}

const routes = (
  <Provider store={store}>
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/deleteAccount"
            name="Delete Account"
            render={(props) => <DeleteAccount {...props} />}
          />
        </Switch>
        <Switch>
          <ProtectedRoute
            path="/resetPassword/:token"
            name="Reset Password"
            component={ForgotPassword}
          />
        </Switch>
        <Switch>
          <PrivateRoute path="/home" name="Home" component={App} />
        </Switch>
        <Switch>
          <PrivateRouteAdmin
            path="/admin_area"
            name="Admin_area"
            component={AppAdmin}
          />
        </Switch>
        <Switch>
          <PrivateRouteAdmin
            path="/application_users"
            name="Application_users"
            component={AppAdmin}
          />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(
  routes,

  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
