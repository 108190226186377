import { SET_SELECTED_FILE } from "../actions/types";


const initialState = {
  
  selectedFiles: [],
}; 
// RR: This function takes in action because we are going to dispatch actions to reducer
 function selectFiles (state = initialState, action) {
  switch (action.type) {
      case SET_SELECTED_FILE :
        return {
          ...state,
          selectedFiles: action.payload
        };
    default:
      return state;
  }
}
 

export default selectFiles;