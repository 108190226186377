import { SET_CURRENTLANG} from "../actions/types";

const initialState = {

  selectedLang: [],

};
// RR: This function takes in action because we are going to dispatch actions to reducer
function langReducer(state = initialState, action) {
    
  switch (action.type) {
    case SET_CURRENTLANG:
      return {
        ...state,
        selectedLang: action.payload,
      };
  
    default:
      return state;
  }
}

export default langReducer;