import {
  GET_LISTENING_STREAM_FOR_ARTIST,
  GET_NEW_LISTENING_STREAM_FOR_ARTIST,
  GET_NB_LISTENING_PER_WEEK,
  GET_TOP_ALBUMS_LISTENING,
  GET_ALL_ALBUMS_LISTENING,
  GET_TOP_TRACKS_LISTENING,
  GET_TOP_ALBUMS_LISTENING_PROGRESS,
} from "../actions/types";

const initialState = [];

function listeningReducer(listenings = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LISTENING_STREAM_FOR_ARTIST:
      return [...listenings, payload];

    case GET_NEW_LISTENING_STREAM_FOR_ARTIST:
      return [...listenings, payload];

    case GET_NB_LISTENING_PER_WEEK:
      return [...listenings, payload];

    case GET_TOP_ALBUMS_LISTENING:
      return [...listenings, payload];

    case GET_ALL_ALBUMS_LISTENING:
      return [...listenings, payload];

    case GET_TOP_TRACKS_LISTENING:
      return [...listenings, payload];

    case GET_TOP_ALBUMS_LISTENING_PROGRESS:
      return [...listenings, payload];

    default:
      return listenings;
  }
}

export default listeningReducer;
