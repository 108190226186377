import { SET_PROGRESS_INFO } from "../actions/types";

const initialState = {
    progressInfoStatus: [],
};

function progressInfoStatus(state = initialState, action) {
  switch (action.type) {
    case SET_PROGRESS_INFO:
      return {
        ...state,
        progressInfoStatus: action.payload,
      };
    default:
      return state;
  }
}

export default progressInfoStatus;