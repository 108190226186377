import { SET_ALBUM_ID } from "../actions/types";

const initialState = {
    albumIdSelected: [],
};

function albumIdSelected(state = initialState, action) {
  switch (action.type) {
    case SET_ALBUM_ID:
      return {
        ...state,
        albumIdSelected: action.payload,
      };
    default:
      return state;
  }
}

export default albumIdSelected;