import { combineReducers } from "redux";
import userReducer from "./userReducer";
import tokenReducer from "./tokenReducer";
import playlistReducer from "./playlistReducer";
import songsReducer from "./songsReducer";
import albumsReducer from "./albumsReducer";
import artistsReducer from "./artistsReducer";
import uiReducer from "./uiReducer";
import browseReducer from "./browseReducer";
import soundReducer from "./soundReducer";
import auth from "./auth";
import artist from "./artist";
import album from "./album";
import selectFiles from "./dropzoneReducer";
import selectedPhoto from "./uploadPhotoReducer";
import artistFollowers from "./artistFollowers";
import song from "./song";
import listeningReducer from "./listeningReducer";
import streamReducer from "./streamReducer";
import forgotPassword from "./forgotPassword";
import langReducer from "./langReducer";
import musicStatus from "./musicReducer";
import percentageInfoStatus from "./percentageInfoReducer";
import progressInfoStatus from "./progressInfoReducer";
import modalCreateAlbumStatus from "./modalCreateAlbumReducer";
import requestUploadFilesStatus from "./requestUploadFilesReducer";
import createAlbumStatus from "./createAlbumDoneReducer";
import modalUpdateAlbumStatus from "./modalUpdateAlbumReducer";
import albumIdSelected from "./albumSelectedReducer";
import trackStatus from "./trackReducer";
import profileArtistStatus from "./profileArtistReducer";
import userIdSelected from "./userSelectedReducer";
import onChatStatus from "./chatReducer";
import message from "./messageReducer";
import authAdmin from "./authAdmin";

export default combineReducers({
  auth,
  artist,
  userReducer,
  tokenReducer,
  playlistReducer,
  songsReducer,
  albumsReducer,
  artistsReducer,
  uiReducer,
  browseReducer,
  soundReducer,
  album,
  selectFiles,
  selectedPhoto,
  artistFollowers,
  song,
  listeningReducer,
  streamReducer,
  forgotPassword,
  langReducer,
  musicStatus,
  percentageInfoStatus,
  progressInfoStatus,
  modalCreateAlbumStatus,
  requestUploadFilesStatus,
  createAlbumStatus,
  modalUpdateAlbumStatus,
  albumIdSelected,
  trackStatus,
  profileArtistStatus,
  userIdSelected,
  onChatStatus,
  message,
  authAdmin
});
