import {
  GET_STREAM_LIVE_STATUS,
  GET_STREAM_JOINED_USERS,
} from "../actions/types";

const initialState = [];

function streamReducer(streams = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_STREAM_LIVE_STATUS:
      return [...streams, payload];

    case GET_STREAM_LIVE_STATUS:
      return [...streams, payload];

    default:
      return streams;
  }
}

export default streamReducer;
