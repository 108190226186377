import { DELETE_TRACK_STATUS, UPLOAD_TRACK_STATUS, SET_PLAY_TRACKS } from "../actions/types";

const initialState = {
    trackDeleteStatus: [],
    trackUploadStatus: [],
    playTrack: false,
};

function trackStatus(state = initialState, action) {
    switch (action.type) {
        case DELETE_TRACK_STATUS:
            return {
                ...state,
                trackDeleteStatus: action.payload,
            };
        case UPLOAD_TRACK_STATUS:
            return {
                ...state,
                trackUploadStatus: action.payload,
            };
        case SET_PLAY_TRACKS:
            return {
                ...state,
                playTrack: action.payload,
            };
        default:
            return state;
    }
}

export default trackStatus;