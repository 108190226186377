import { GO_TO_STREAM_CHAT } from "../actions/types";

const initialState = {
  onChatStatus: false,
};

function onChatStatus(state = initialState, action) {
  switch (action.type) {
    case GO_TO_STREAM_CHAT:
      return {
        ...state,
        onChatStatus: action.payload,
      };
    default:
      return state;
  }
}

export default onChatStatus;
