import { UPDATE_HEADER_TITLE } from "../actions/types";

const defaultState = {
  title: 0
};


export function uiReducer(state = defaultState, action) {

  switch (action.type) {
    case UPDATE_HEADER_TITLE:
      return {
        ...state,
        title: action.title
      };

    default:
      return state;
  }

};

export default uiReducer;
