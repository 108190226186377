import { SET_USER_ID } from "../actions/types";

const initialState = {
  userIdSelected: null,
};

function userIdSelected(state = initialState, action) {
  switch (action.type) {
    case SET_USER_ID:
      return {
        ...state,
        userIdSelected: action.payload,
      };
    default:
      return state;
  }
}

export default userIdSelected;