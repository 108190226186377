import axios from "axios";
var baseUrl = require("../config/server_config.json");
var config = require("../config/config.json");

let theBaseUrl;
Object.keys(config).map((mode) => {
  theBaseUrl =
    config[mode] == true ? baseUrl[mode].baseUrl.conString : theBaseUrl;
});


class AuthService {
  loginArtist(artist_email, artist_password) {
    var theUrl = theBaseUrl + "/artist/api/authenticate";
    
    var theBody = {
      artist_email: artist_email,
      artist_password: artist_password,
    };
   
    return axios.post(theUrl, theBody).then((response) => {
      console.log("inside API: " + JSON.stringify(response));
      return response.data;
    });
  }


  loginAdmin(theAdminEmail, theArtistPassword) {
    var theUrl = theBaseUrl + "/admin/api/loginAdmin";
    
    var theBody = {
      admin_email: theAdminEmail,
      admin_password: theArtistPassword,
    };
   
    return axios.post(theUrl, theBody).then((response) => {
      console.log("inside API: " + JSON.stringify(response));
      return response.data;
    });
  }


}

export default new AuthService();
