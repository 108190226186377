import { MUSIC_ENDED } from "../actions/types";

const initialState = {
  musicStatus: [],
};

function musicStatus(state = initialState, action) {
  switch (action.type) {
    case MUSIC_ENDED:
      return {
        ...state,
        musicStatus: action.payload,
      };
    default:
      return state;
  }
}

export default musicStatus;
