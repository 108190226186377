import {
  GET_ALL_STREAM_LIVE_MESSAGES,
  ADD_NEW_MESSAGE_IN_STREAM_LIVE,
} from "../actions/types";

const initialState = [];

function message(messages = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_STREAM_LIVE_MESSAGES:
      return [...messages, payload];

    case ADD_NEW_MESSAGE_IN_STREAM_LIVE:
      return [...messages, payload];

    default:
      return messages;
  }
}

export default message;
