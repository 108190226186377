export const GET_USER_ID = "GET_USER_ID";
export const GET_LOGGIN_ARTIST_BY_ID = "GET_LOGGIN_ARTIST_BY_ID";

export const FORGOT_PASSWORD_ARTIST = "FORGOT_PASSWORD_ARTIST";
export const AUTH_FORGOT_PASSWORD_ARTIST = "AUTH_FORGOT_PASSWORD_ARTIST";
export const EXPIRED_FORGOT_PASSWORD_TOKEN = "EXPIRED_FORGOT_PASSWORD_TO";
export const CHANGE_FORGOT_PASSWORD_ARTIST = "CHANGE_FORGOT_PASSWORD_ARTIST";

export const VALIDATE_ACCOUNT_ARTIST_BY_EMAIL =
  "VALIDATE_ACCOUNT_ARTIST_BY_EMAIL";
export const VALIDATE_ACCOUNT_ARTIST_BY_PHONE =
  "VALIDATE_ACCOUNT_ARTIST_BY_PHONE";
export const GENERATE_SECRET_PHONE_KEY_AUTH = "GENERATE_SECRET_PHONE_KEY_AUTH";
export const ACTIVE_ACCOUNT_ARTIST = "ACTIVE_ACCOUNT_ARTIST";
export const UPDATE_ACCOUNT_ARTIST_BY_EMAIL = "UPDATE_ACCOUNT_ARTIST_BY_EMAIL";
export const GENERATE_NEW_SECRET_KEY_AUTH = "GENERATE_NEW_SECRET_KEY_AUTH";
export const GENERATE_NEW_SECRET_KEY_AUTH_BY_PHONE =
  "GENERATE_NEW_SECRET_KEY_AUTH_BY_PHONE";
export const SET_ACCOUNT_ARTIST_BANNED = "SET_ACCOUNT_ARTIST_BANNED";
export const CHANGE_STREAM_INFO_FOR_ARTIST = "CHANGE_STREAM_INFO_FOR_ARTIST";
export const REQUEST_UPLOAD_FILES_TO_THE_SERVER =
  "REQUEST_UPLOAD_FILES_TO_THE_SERVER";
export const CREATE_ALBUM_PROCESS_SUCCESSFULLY_DONE =
  "CREATE_ALBUM_PROCESS_SUCCESSFULLY_DONE";
export const SET_ALBUM_ID = "SET_ALBUM_ID";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";

export const GET_ERRORS = "GET_ERRORS";

export const SET_CURRENT_ARTIST = "SET_CURRENT_ARTIST";
export const SET_CURRENT_ADMIN = "SET_CURRENT_ADMIN";

export const EDIT_ARTIST_ACCOUNT = "EDIT_ARTIST_ACCOUNT";
export const EDIT_ARTIST_ACCOUNT_WITHOUT_AVATAR =
  "EDIT_ARTIST_ACCOUNT_WITHOUT_AVATAR";

export const SIGNUP_ARTIST = "SIGNUP_ARTIST";
export const LOGOUT = "LOGOUT";
export const CHANGE_PASSWORD_ARTIST = "CHANGE_PASSWORD_ARTIST";

export const SET_PROGRESS_INFO = "SET_PROGRESS_INFO";
export const SET_PERCENTAGE_INFO_STATUS = "SET_PERCENTAGE_INFO_STATUS";

export const CREATE_ALBUM = "CREATE_ALBUM";
export const CREATE_TRACK = "CREATE_TRACK";
export const DELETE_TRACK = "DELETE_TRACK";
export const DELETE_MULTI_TRACK = "DELETE_TRACK";
export const CREATE_SINGLE = "CREATE_SINGLE";
export const DELETE_TRACK_STATUS = "DELETE_TRACK_STATUS";
export const UPLOAD_TRACK_STATUS = "UPLOAD_TRACK_STATUS";

export const GET_ALBUM = "GET_ALBUM";
export const GET_SONG = "GET_SONG";
export const FETCH_SONGS_BY_ALBUM = "FETCH_SONGS_BY_ALBUM";
export const DELETE_ALBUM = "DELETE_ALBUM";
export const GET_ALBUM_INFO_BY_ID = "GET_ALBUM_INFO_BY_ID";
export const EDIT_ALBUM = "EDIT_ALBUM";
export const EDIT_ALBUM_WITOUT_COVER = "EDIT_ALBUM_WITOUT_COVER";

export const SET_SELECTED_FILE = "SET_SELECTED_FILE";

export const SET_UPLOAD_PHOTO = "SET_UPLOAD_PHOTO";
export const SET_UPLOAD_AVATAR = "SET_UPLOAD_AVATAR";
export const SET_UPLOAD_PROFILE_IMG = "SET_UPLOAD_PROFILE_IMG";
export const SET_UPLOAD_PROFILE_MOBILE_IMG = "SET_UPLOAD_PROFILE_MOBILE_IMG";

export const GET_ARTIST_FOLLOWERS = "GET_ARTIST_FOLLOWERS";
export const GET_ARTIST_NEW_FOLLOWERS = "GET_ARTIST_NEW_FOLLOWERS";
export const GET_FOLLOWERS_BY_COUNTRY_EN = "GET_FOLLOWERS_BY_COUNTRY_EN";
export const GET_FOLLOWERS_BY_COUNTRY_FR = "GET_FOLLOWERS_BY_COUNTRY_FR";
export const GET_NB_FOLLOWERS_PER_WEEK = "GET_NB_FOLLOWERS_PER_WEEK";
export const GET_LISTENING_STREAM_FOR_ARTIST =
  "GET_LISTENING_STREAM_FOR_ARTIST";
export const GET_NEW_LISTENING_STREAM_FOR_ARTIST =
  "GET_NEW_LISTENING_STREAM_FOR_ARTIST";
export const GET_NB_LISTENING_PER_WEEK = "GET_NB_LISTENING_PER_WEEK";
export const GET_TOP_ALBUMS_LISTENING = "GET_TOP_ALBUMS_LISTENING";
export const GET_ALL_ALBUMS_LISTENING = "GET_ALL_ALBUMS_LISTENING";
export const GET_TOP_TRACKS_LISTENING = "GET_TOP_TRACKS_LISTENING";
export const GET_TOP_ALBUMS_LISTENING_PROGRESS =
  "GET_TOP_ALBUMS_LISTENING_PROGRESS";
export const GET_FOLLOWERS_INFO = "GET_FOLLOWERS_INFO";
export const SET_USER_ID = "SET_USER_ID";

export const GET_STREAM_LIVE_STATUS = "GET_STREAM_LIVE_STATUS";

export const UPDATE_HEADER_TITLE = "UPDATE_HEADER_TITLE";

export const SET_CURRENTLANG = "SET_CURRENTLANG";

export const MUSIC_ENDED = "MUSIC_ENDED";
export const SHOW_MODAL_CREATE_ALBUM = "SHOW_MODAL_CREATE_ALBUM";
export const SHOW_MODAL_UPDATE_ALBUM = "SHOW_MODAL_UPDATE_ALBUM";
export const SET_PLAY_TRACKS = "SET_PLAY_TRACKS";

export const EDIT_ARTIST_ACCOUNT_WITH_PROFILE_PHOTO =
  "EDIT_ARTIST_ACCOUNT_WITH_PROFILE_PHOTO";
export const EDIT_ARTIST_ACCOUNT_WITH_COVER_PHOTO =
  "EDIT_ARTIST_ACCOUNT_WITH_COVER_PHOTO";

export const GO_TO_STREAM_CHAT = "GO_TO_STREAM_CHAT";

export const GET_ALL_STREAM_LIVE_MESSAGES = "GET_ALL_STREAM_LIVE_MESSAGES";
export const ADD_NEW_MESSAGE_IN_STREAM_LIVE = "ADD_NEW_MESSAGE_IN_STREAM_LIVE";
export const GET_STREAM_JOINED_USERS = "GET_STREAM_JOINED_USERS";
export const CHECK_EMAIL_TYPE = "CHECK_EMAIL_TYPE";
export const CHECK_USER = "CHECK_USER";
export const GET_ALL_ARTIST_LIST = "GET_ALL_ARTIST_LIST";
export const SET_ARTIST_AS_EXCLUSIVE = "SET_ARTIST_AS_EXCLUSIVE"
export const SET_TRACK_AS_EXCLUSIVE  = "SET_TRACK_AS_EXCLUSIVE"

export const GET_APPLICATION_USERS ="GET_APPLICATION_USERS"


     
