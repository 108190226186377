import axios from "axios";
var baseUrl = require("../config/server_config.json");
var config = require("../config/config.json");

let theBaseUrl;
Object.keys(config).map((mode) => {
  theBaseUrl =
    config[mode] == true ? baseUrl[mode].baseUrl.conString : theBaseUrl;
});

class PasswordService {
  forgotPasswordArtist(artist_email) {
    var theUrl = theBaseUrl + "/artist/api/forgotPasswordAuthentication";

    var theBody = {
      anArtistEmail: artist_email,
    };

    return axios.post(theUrl, theBody).then((response) => {
      console.log("inside API: " + JSON.stringify(response));
      return response.data;
    });
  }

  changeForgotPasswordArtist(anArtistId, anArtistEmail, anNewPassword) {
   
    var theBody = {
      anArtistId: anArtistId,
      anArtistEmail: anArtistEmail,
      anNewPassword: anNewPassword,
    };

    var theUrl = theBaseUrl + "/artist/api/changeForgotPasswordArtist";

    return axios.post(theUrl, theBody).then((response) => {
      console.log("inside API: " + JSON.stringify(response));
      return response.data;
    });
  }
}

export default new PasswordService();
