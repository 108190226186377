import {

    GET_SONG,
    DELETE_TRACK,
    DELETE_MULTI_TRACK
    
  } from "../actions/types";
  
  const initialState = [];
  
  function song(songs = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
  
        case  GET_SONG:
        return [payload];

        case   DELETE_TRACK:
        return [payload];

        case   DELETE_MULTI_TRACK:
        return [payload];

        default:
        return songs;
    }
  };
  
  export default song;
  