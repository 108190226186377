import {
  FORGOT_PASSWORD_ARTIST,
  SIGNUP_ARTIST,
  EDIT_ARTIST_ACCOUNT,
  EDIT_ARTIST_ACCOUNT_WITHOUT_AVATAR,
  CHANGE_PASSWORD_ARTIST,
  CHANGE_FORGOT_PASSWORD_ARTIST,
  VALIDATE_ACCOUNT_ARTIST_BY_EMAIL,
  VALIDATE_ACCOUNT_ARTIST_BY_PHONE,
  GENERATE_SECRET_PHONE_KEY_AUTH,
  ACTIVE_ACCOUNT_ARTIST,
  UPDATE_ACCOUNT_ARTIST_BY_EMAIL,
  GENERATE_NEW_SECRET_KEY_AUTH,
  GENERATE_NEW_SECRET_KEY_AUTH_BY_PHONE,
  SET_ACCOUNT_ARTIST_BANNED,
  CHANGE_STREAM_INFO_FOR_ARTIST,
  GET_FOLLOWERS_INFO,
  EDIT_ARTIST_ACCOUNT_WITH_PROFILE_PHOTO,
  EDIT_ARTIST_ACCOUNT_WITH_COVER_PHOTO,
  CHECK_EMAIL_TYPE,
  CHECK_USER,
  GET_ALL_ARTIST_LIST,
  SET_ARTIST_AS_EXCLUSIVE,
  SET_TRACK_AS_EXCLUSIVE
} from "../actions/types";

const initialState = [];

function artist(artists = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FORGOT_PASSWORD_ARTIST:
      return [...artists, payload];

    case SIGNUP_ARTIST:
      return [...artists, payload];

    case EDIT_ARTIST_ACCOUNT:
      return [...artists, payload];

    case EDIT_ARTIST_ACCOUNT_WITHOUT_AVATAR:
      return [...artists, payload];

    case CHANGE_PASSWORD_ARTIST:
      return [...artists, payload];

    case CHANGE_FORGOT_PASSWORD_ARTIST:
      return [...artists, payload];

    case VALIDATE_ACCOUNT_ARTIST_BY_EMAIL:
      return [...artists, payload];

    case VALIDATE_ACCOUNT_ARTIST_BY_PHONE:
      return [...artists, payload];

    case GENERATE_SECRET_PHONE_KEY_AUTH:
      return [...artists, payload];

    case ACTIVE_ACCOUNT_ARTIST:
      return [...artists, payload];

    case UPDATE_ACCOUNT_ARTIST_BY_EMAIL:
      return [...artists, payload];

    case GENERATE_NEW_SECRET_KEY_AUTH:
      return [...artists, payload];

    case GENERATE_NEW_SECRET_KEY_AUTH_BY_PHONE:
      return [...artists, payload];

    case SET_ACCOUNT_ARTIST_BANNED:
      return [...artists, payload];

    case CHANGE_STREAM_INFO_FOR_ARTIST:
      return [...artists, payload];

    case GET_FOLLOWERS_INFO:
      return [...artists, payload];

    case EDIT_ARTIST_ACCOUNT_WITH_PROFILE_PHOTO:
      return [...artists, payload];

    case EDIT_ARTIST_ACCOUNT_WITH_COVER_PHOTO:
      return [...artists, payload];

    case CHECK_EMAIL_TYPE:
      return [...artists, payload];

      
    case CHECK_USER:
      return [...artists, payload];

    case GET_ALL_ARTIST_LIST:
      return [...artists, payload];

    case SET_ARTIST_AS_EXCLUSIVE:
      return [...artists, payload];

    case SET_TRACK_AS_EXCLUSIVE:
      return [...artists, payload];

    default:
      return artists;
  }
}

export default artist;
