import { GET_ERRORS,AUTH_FORGOT_PASSWORD_ARTIST } from "../actions/types";
import isEmpty from "../validation/isEmpty";

const initialState = {
  isAuthenticated: false,
  artist: {}
}; 

 function forgotPassword (state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return {
     
        ...state,
       
        artist: action.payload
      };
      case AUTH_FORGOT_PASSWORD_ARTIST:
        return {
          ...state,
          isAuthenticated: !isEmpty(action.payload),
          artist: action.payload
        };
    default:
      return state;
  }
}
 

export default forgotPassword;