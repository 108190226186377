import {
  CREATE_ALBUM,
  CREATE_SINGLE,
  GET_ALBUM,
  FETCH_SONGS_BY_ALBUM,
  DELETE_ALBUM,
  GET_ALBUM_INFO_BY_ID,
  EDIT_ALBUM,
  EDIT_ALBUM_WITOUT_COVER,
} from "../actions/types";

const initialState = [];

function album(albums = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_ALBUM:
      return [...albums, payload];

    case CREATE_SINGLE:
      return [...albums, payload];

    case GET_ALBUM:
      return [payload];

    case FETCH_SONGS_BY_ALBUM:
      return [payload];

    case DELETE_ALBUM:
      return [payload];

    case GET_ALBUM_INFO_BY_ID:
      return [payload];

    case EDIT_ALBUM:
      return [...albums, payload];

    case EDIT_ALBUM_WITOUT_COVER:
      return [...albums, payload];

    default:
      return albums;
  }
}

export default album;
