
import axios from "axios";

const setPasswordToken = token => {
  console.log("setPasswordToken is called");
  if (token) {
  
    axios.defaults.headers.common = {
      "x-access-token": token
    };
  } else {
    
    axios.defaults.headers.common = {
    
      "x-access-token": token
    };
  }
};
export default setPasswordToken;