import {
  GET_ARTIST_FOLLOWERS,
  GET_ARTIST_NEW_FOLLOWERS,
  GET_FOLLOWERS_BY_COUNTRY_EN,
  GET_FOLLOWERS_BY_COUNTRY_FR,
  GET_NB_FOLLOWERS_PER_WEEK,
} from "../actions/types";

const initialState = [];

function artistFollowers(followers = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ARTIST_FOLLOWERS:
      return [...followers, payload];

    case GET_ARTIST_NEW_FOLLOWERS:
      return [...followers, payload];

    case GET_FOLLOWERS_BY_COUNTRY_EN:
      return [...followers, payload];

      case GET_FOLLOWERS_BY_COUNTRY_FR:
      return [...followers, payload];

    case GET_NB_FOLLOWERS_PER_WEEK:
      return [...followers, payload];

    default:
      return followers;
  }
}

export default artistFollowers;
